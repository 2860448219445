.occasionList {
    flex-direction: row;
    flex: 4;
    margin: 20px;
}

.occasionTitle {
    padding: 10px;
}

