.productForm {
  margin-left: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.productFormTitle {
  padding-left: 20px;
  padding-top: 20px;
}

.productId {
  padding-left: 20px;
  font-size: 0.8em;
  color: gray;
}

.productForm {
  margin-top: 10px;
  margin-bottom: 20px;
}

.fileContainer {
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 5%);
}

.fileContainer .uploadPictureContainer {
  margin: 0;
}

.productImage {
  width: 300px !important;
  align-self: center;
}


.productFormItem {
  margin-bottom: 10px;
  width: 650px;
}

.productFormItem>label {
  color: gray;
  font-weight: 600;
  

}

.productFormItem>legend {
  color: gray;
  font-weight: 600;
  padding-top: 5px;
  padding-left: 10px;
}

.productFormItem>input {
  padding: 10px;
  width: -webkit-fill-available;
}

.productFormItem>select {
  padding: 10px;
}

.productFormButton {
  margin-top: 10px !important;
  padding: 10px 10px !important;
  border: none !important;
  border-radius: 10px !important;
  background-color: #1976d2 !important;
  color: white !important;
  font-weight: 600 !important;
  cursor: pointer !important;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
}

.checkbox-container>div {
  height: 30px;
  width: 199px;
}

.checkbox-container>div>label>input {
  margin-right: 5px;
}

.categoryElem {
  width: 599px !important;
  height: auto !important;
  margin-top: 10px;
}

.subCategoryElem>label>input {
  margin-right: 5px;
}

.featured-image {
  width: 230px;
}

.image-title {
  padding: 5px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.featured-image input {
  display: none;
}

#product-editable-image .product-image {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #3f51b5;
  text-align: center;
  height: 100%;
  position: relative;
  padding: 100% 0 0 0;
  background-size: cover;
  background-position: center;
}

#product-editable-image .product-image .product-img-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  height: 95%;
  overflow: hidden;
  border: 5px;
  display: block;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 7px;
}

#product-editable-image .product-image .product-img-container img {
  display: none;
}

#product-editable-image .product-image .remove-image {
  color: #d35400;
  position: absolute;
  right: 0px;
  top: -2px;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s all;
  z-index: 1;
}

#product-editable-image .product-image img {
  width: 100%;
  height: 100%;
  display: block;
}

#product-editable-image .product-image:hover .remove-image {
  opacity: 1;
}

#product-editable-image .image-loading {
  animation: loadingFadeIn 1.5s infinite alternate;
}

#product-editable-image .image-uploaded {
  animation-name: imageUploaded;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes loadingFadeIn {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@keyframes imageUploaded {
  0% {
    background-color: #008000;
  }

  100% {
    background-color: #fff;
  }
}