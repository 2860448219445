.productList {
    flex-direction: row;
    flex: 4;
    margin: 20px;
}

.productListItem {
    display: flex;
    align-items: center;
}


.productListImg {
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
    object-fit: contain;
    border:1px solid lightgray;
    margin-right: 10px;
    margin-top:2px;
    margin-bottom:2px;
}

.productListEdit {
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.productListDelete {
    color: red;
    cursor: pointer;
}

.productAddContainer {
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
}

.productAddButton {
    width: 180px;
    border: none;
    padding: 15px;
    background-color: teal;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}