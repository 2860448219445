.sidebar {
  height: auto;
  min-height: calc(100vh);
  background-color: rgb(251, 251, 255);
  top: 50px;
}

.sidebarWrapper {
  padding: 20px;
  color: #555;
}

.sidebarMenu {
  margin-bottom: 10px;
}

.sidebarTitle {
  font-size: 13px;
  color: rgb(187, 186, 186);
}

.sidebarList {
  list-style: none;
  padding: 5px;
}

.sidebarListItem {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem.active,
.sidebarListItem:hover {
  background-color: rgb(240, 240, 255);
}

.sidebarIcon {
  margin-right: 5px;
  font-size: 20px !important;
}

.bottomLink {
  position: fixed;
  bottom: 20px;
}