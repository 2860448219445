.relationList {
    flex-direction: row;
    flex:4;
    margin:20px;
}

.relationListItem {
    display: flex;
    align-items: center;
}

.relationTitle {
    padding: 10px;
}