.decoderForm {
    margin-top: 40px;
    margin-left: 20px;
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  
  .decodedInfo {
    font-size: 14px;
    margin: 5px;
  }
  
  .decodedInfo p {
    margin-top: 5px;
  }
  .decodedInfo ul {
    margin-left: 45px;
  }