.container {
    display: flex;
}

.link {
    text-decoration: none;
    color: inherit;
}

.home-text {
    padding: 20px;
}

.content {
    flex-direction: row;
}