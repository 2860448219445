.upload-image-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid gray;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
}

.upload-image-holder .add-icon {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 46%;
    left: 10%;
    width: 36px;
    height: 36px;
    transform: translate(-50%, -50%);
}

.upload-image-holder .add-icon label {
    cursor: pointer;
    padding: 120px;
}

.hide-upload-input {
    display: none;
}

.addImgInfo {
    font-size: 0.9em;
    color: gray;
}